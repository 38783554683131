import { PropsWithChildren } from "react";
import { ChildContainer, Doodle, Root } from "./styles";

export function DoodleHeader({ children }: PropsWithChildren<unknown>) {
  return (
    <Root>
      <Doodle />
      {children && <ChildContainer>{children}</ChildContainer>}
    </Root>
  );
}
