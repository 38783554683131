import { styled } from "@bigspring/core-components/styles";
import DoodleBase from "@assets/doodle.svg";

export const ChildContainer = styled("figure")`
  align-items: center;
  background: ${({ theme }) => theme.kinetic.v2.palette.rgb.blueDark};
  border: 6px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 0;
  padding: 0.5rem;
  position: relative;
  width: 100px;

  & > svg {
    height: auto;
    fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
    width: 100%;
  }
`;

export const Root = styled("header")`
  align-items: center;
  background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blueDark};
  display: flex;
  height: 33vh;
  height: 33dvh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Doodle = styled(DoodleBase)`
  height: auto;
  left: 0;
  min-width: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  position: absolute;
  fill: ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.5)};
  top: 0;
  width: auto;
`;
export const LightDoodle = styled(Doodle)`
  fill: ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.1)};
`;

export const BlueDoodle = styled(Doodle)`
  fill: rgb(8, 85, 130);
`;
